export interface Lob {
  lob_id: number
  lob_name: string
}

export enum LobId {
  WG_ID = 1,
  KMB_ID = 2,
  KMOB_ID = 3,
  UC_ID = 4,
}

export interface LobIcons {
  lob_id: number
  lob_icon: any
}

export interface LobList extends Lob {
  lob_icon: any
  is_selected: boolean
}
